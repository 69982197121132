.card {
  background-image: radial-gradient(
    circle at 20% 25%,
    rgb(3, 51, 103) 0%,
    rgba(73, 0, 39, 0.724) 100%
  );
}

.ListGroup {
  background-color: radial-gradient(
    circle at 20% 25%,
    rgb(3, 51, 103) 0%,
    rgba(73, 0, 39, 0.724) 100%
  );
}

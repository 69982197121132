.header {
  display: flex;
  flex-direction: row;
}

.container {
  background-image: radial-gradient(
    circle at 20% 25%,
    rgb(3, 51, 103) 0%,
    rgba(73, 0, 39, 0.724) 100%
  );
}

.header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;

  display: flex;
  flex-direction: row;
}

.header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  background: #ff4820;
  border: 2px solid #ff4820;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1050px) {
  .header {
    flex-direction: column;
  }

  .header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .header-content__people {
    flex-direction: column;
  }

  .header-content__people p {
    margin: 0;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
